<!-- =========================================================================================
  File Name: FullPage.vue
  Description: Full page layout
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="layout--full-page ">
    <div class="z-40 absolute top-0 right-0 ">
      <i18n class="p-8"  />
    </div>

    <div class="z-40 absolute h-32 w-32 vx-col hidden lg:block lg:w-2/3">
      <div class="pt-8 pl-10 left-0 top-0 h-16 w-16">
        <!-- <logo class="w-48 fill-current text-primary" /> -->
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import Logo from "@/layouts/components/Logo.vue";
import I18n from "@/layouts/components/navbar/components/I18n.vue";

export default {
  components: {
    Logo,
    I18n
  }
};
</script>
